import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { connect } from 'react-redux'
import useRecentViewedProductList from 'components/custom-hooks/useRecentViewedProductList'
import ProductList from 'components/product/product-list'
import ProductListSlider from 'components/product/product-list-slider'
import Typo from 'constants/typography'
import { throttleCallback } from 'lib/utils/common/commonUtils'
import RecentlyViewSkeleton from '../recent-product-skeleton'
import { fillClassNamePrefix, pushSpecialElement } from '../utils'
import styles from './styles.scss'

const RecentProductList = ({
  auth,
  isPhone,
  onHomepage,
  onSearchSection,
  refType,
  rightMargin,
  title,
}) => {
  const [firstProductRef, setFirstProductRef] = useState(null)
  const [seeAllCardWidth, setSeeAllCardWidth] = useState(164)
  const [seeAllCardHeight, setSeeAllCardHeight] = useState(216)
  const [marginRight, setMarginRight] = useState(4)

  const { loading, userProductList } = useRecentViewedProductList(auth)

  const recentProductList = useMemo(() => {
    const newProductList = userProductList.slice(0, 7)

    pushSpecialElement(userProductList, newProductList)

    return newProductList
  }, [userProductList])

  const updateSeeAllCardWidth = useCallback(() => {
    const firstProductObj =
      firstProductRef?.current?.firstChild?.getBoundingClientRect() // product item image element
    const firstProductWidth = firstProductObj?.width
    const firstProductHeight = firstProductObj?.height

    if (firstProductWidth !== seeAllCardWidth) {
      setSeeAllCardWidth(firstProductWidth)
    }

    if (firstProductHeight !== seeAllCardHeight) {
      setSeeAllCardHeight(firstProductHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstProductRef])

  // set see all card width
  useEffect(() => {
    updateSeeAllCardWidth()
    window.addEventListener(
      'resize',
      throttleCallback(updateSeeAllCardWidth, 100),
      true,
    )

    return () => {
      window.removeEventListener('resize', updateSeeAllCardWidth)
    }
  }, [updateSeeAllCardWidth])

  const updateNextBtnRightMargin = useCallback(() => {
    if (rightMargin !== marginRight) {
      setMarginRight(rightMargin)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rightMargin])

  // update slider next button right distance
  useEffect(() => {
    updateNextBtnRightMargin()
    window.addEventListener(
      'resize',
      throttleCallback(updateNextBtnRightMargin, 100),
      true,
    )

    return () => {
      window.removeEventListener('resize', updateNextBtnRightMargin)
    }
  }, [updateNextBtnRightMargin])

  if (loading && isPhone) {
    return (
      <Fragment>
        <style jsx>{styles}</style>
        <span className={fillClassNamePrefix('divider')} />
        <RecentlyViewSkeleton />
      </Fragment>
    )
  }

  if (!recentProductList.length) {
    return null
  }

  return (
    <Fragment>
      {isPhone && <span className={fillClassNamePrefix('divider')} />}
      <div
        className={clsx(fillClassNamePrefix('container'), {
          'homepage-container': onHomepage,
        })}
      >
        <style jsx>{styles}</style>
        <div
          className={clsx(fillClassNamePrefix('header-title'), {
            [Typo.subtitle1]: onSearchSection,
            [Typo.title2]: onHomepage,
            'homepage-title': onHomepage,
          })}
        >
          {title}
        </div>
        <div
          className={clsx(fillClassNamePrefix('product-container'), {
            'on-search-section': onSearchSection,
            'homepage-product': onHomepage,
          })}
        >
          {recentProductList.length > 4 || isPhone ? ( // show slider component when user've viewed more than 4 products or view on mobile
            <ProductListSlider
              customArrowProps={{
                class: {
                  leftArrow: fillClassNamePrefix('prev-btn'),
                  rightArrow: fillClassNamePrefix('next-btn'),
                },
                style: {
                  right: `${rightMargin + 16}px`,
                },
              }}
              customSliderOptions={{
                slidesPerView: 'auto',
                spaceBetween: 12,
              }}
              displayBadges={onHomepage}
              displayBullets={false}
              displayDescription
              displayPremiumTags
              displaySwatches
              displayWishlistIcon
              isPhone={isPhone}
              list={recentProductList}
              refId=""
              refType={refType}
              seeAllCardHeight={seeAllCardHeight}
              seeAllCardWidth={seeAllCardWidth}
              setFirstProductRef={setFirstProductRef}
            />
          ) : (
            <ProductList
              displayBadges
              displayDescription
              displayManufacturerName
              displayPrice
              displaySwatches
              displayWishlistIcon
              list={recentProductList}
              refId=""
              refType={refType}
              showFourPerRow
            />
          )}
        </div>
      </div>
    </Fragment>
  )
}

RecentProductList.defaultProps = {
  onHomepage: false,
  onSearchSection: false,
  rightMargin: 4,
}

RecentProductList.propTypes = {
  auth: PropTypes.shape({
    isGuestMode: PropTypes.bool,
    user: PropTypes.shape({
      id_customer: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }).isRequired,
  isPhone: PropTypes.bool.isRequired,
  onHomepage: PropTypes.bool,
  onSearchSection: PropTypes.bool,
  refType: PropTypes.string.isRequired,
  rightMargin: PropTypes.number,
  title: PropTypes.string.isRequired,
}

export default connect((state) => ({
  auth: state.auth,
  isPhone: state.device.isPhone,
}))(RecentProductList)
