import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { connect } from 'react-redux'
import TextField from 'components/common/textfield'
import { locizeTranslation, hasLocizeTranslation } from 'lib/utils/locize'
import Button from 'components/common/button'
import { BUTTON_HTML_TYPE, BUTTON_TYPES } from 'constants/button'
import { useAuth } from 'components/auth/context'
import {
  AUTH_ERROR,
  AUTH_PROVIDER,
  AUTH_MESSAGE,
  AUTH_SIGNUP_METHODS,
  AUTH_UI_STATE,
  AUTH_ERROR_MERGE,
} from 'components/auth/const'
import {
  signUpWithEmailFireBase,
  successfulLogInFireBase,
  getEmailProviderListFireBase,
  logInWithPhoneGetSMSFireBase,
} from 'lib/auth'
import FormItem from 'components/form-item'
import TextFieldPassword from 'components/common/textfield-password'
import { withI18next } from 'lib/i18n/withI18next'
import { segmentPDPAConsentResponded } from 'lib/segment'
import AuthPDPAContent from 'components/auth/pdpa'
import { ontextFieldFocus } from 'components/auth/utils'
import TextFieldPhone from 'components/molecules/textfield-phone'
import SignupFormLocizes, {
  INPUT_NAME_EMAIL,
  INPUT_NAME_FIRST,
  INPUT_NAME_LAST,
  INPUT_NAME_PASSWORD,
} from 'components/auth/signup-form/const'
import { getCountryCodeWithShopID } from 'lib/utils/common/commonUtils'
import { parsePhoneNumber } from 'libphonenumber-js'
import compose from 'recompose/compose'
import UserInfo from 'lib/api/user-info'
import { useFlagsmithGetFlagValue } from 'lib/flagsmith'
import { extractFieldHelperMessages, useSignupForm } from './useSignupForm'
import style from './style.scss'
import phoneStyle from '../shared/phone/style.scss'
import AuthDateOfBirthSelect from '../shared/date-of-birth-select/AuthDateOfBirthSelect'
import { MONTHS_OPTIONS_NUM } from '../shared/date-of-birth-select/const'

const SignupForm = ({
  internationalization,
  mergeHandler,
  t,
  countriesPayload,
}) => {
  // states
  const [phoneHelperLabel, passwordHelperLabel] = locizeTranslation(
    t,
    SignupFormLocizes.labels,
  )
  const [prefix, setPrefix] = useState(
    getCountryCodeWithShopID(internationalization),
  )
  const isAutoLoyaltySignupFlag = useFlagsmithGetFlagValue(
    'EnableLoyaltyAutoSignupFields_20231002',
    false,
  )

  // auth hook
  const [authState, updateAuthState] = useAuth()

  // const
  const isPhoneSignup = authState.signUpMethod === AUTH_SIGNUP_METHODS.PHONE

  // form hooks
  const { errors, formState, setError, handleSubmit, register } = useForm({
    mode: 'onChange',
    defaultValues: {
      [INPUT_NAME_EMAIL]: authState.dataEmail,
    },
  })
  const {
    emailRegister,
    firstNameRegister,
    isDisabledSubmit,
    lastNameRegister,
    passwordRegister,
    setIsLoading,
    passwordFormat,
    phoneRegister,
  } = useSignupForm({
    formState,
    internationalization,
    passwordHelperLabel,
    t,
    register,
    isPhoneSignup,
    applyPasswordPolicy: true,
  })

  const helperFieldProps = extractFieldHelperMessages({
    errors,
    phoneHelperLabel,
    passwordHelperLabel,
  })

  // handlers
  const handleError = (err, userData) => {
    const { email } = userData

    if (AUTH_ERROR_MERGE.includes(err?.code)) {
      updateAuthState({
        dataEmail: email,
        uiState: AUTH_UI_STATE.merge,
      })
    } else if (AUTH_ERROR.emailExist !== err?.code) {
      updateAuthState({ snackBar: err })
    } else if (AUTH_ERROR.emailExist === err?.code) {
      setError(AUTH_PROVIDER.email, 'duplicate', AUTH_MESSAGE.emailUsed)
    }

    updateAuthState({ overlayLoading: false })
    setIsLoading(false)
  }

  const handleFormSubmit = async (values, e) => {
    e?.preventDefault()

    const {
      email,
      password,
      firstName,
      lastName,
      phone,
      date,
      month,
      year,
      postcode,
    } = values

    const stringPostcode = postcode?.toString()
    const fullBirthday = `${date}-${month}-${year}`
    const netCoreMonth = MONTHS_OPTIONS_NUM[month]
    const netCoreDOB = `${year}-${netCoreMonth}-${date}`

    const fullPhone =
      !isPhoneSignup && isAutoLoyaltySignupFlag
        ? parsePhoneNumber(`+${prefix}${phone}`)?.number
        : undefined
    const netCorePhone = fullPhone ? fullPhone.substring(1) : undefined

    const authData = {
      dataFirstName: firstName,
      dataLastName: lastName,
      dataDateOfBirth: fullBirthday,
      ...(!isPhoneSignup && { dataPhone: fullPhone }),
      ...(email && { dataEmail: email, dataPassword: password }),
      ...(stringPostcode && { dataPostcode: stringPostcode }),
    }

    const defaultAuthSource = isPhoneSignup
      ? AUTH_PROVIDER.phone
      : AUTH_PROVIDER.email

    updateAuthState({
      ...authData,
      overlayLoading: true,
      source: mergeHandler ? authState.source : defaultAuthSource,
    })

    setIsLoading(true)

    try {
      if (!isPhoneSignup) {
        const res = await signUpWithEmailFireBase(
          email,
          password,
          firstName,
          lastName,
        )

        successfulLogInFireBase(res)
        segmentPDPAConsentResponded()

        /**
         * Description: updateCurrentUserInfo will only be executed for new sign up using email address. This is part of auto enroll loyalty program to new customers
         */
        if (isAutoLoyaltySignupFlag) {
          await UserInfo.updateCurrentUserInfo({
            phone_signup: fullPhone,
            loyalty_signup: true,
            postcode: stringPostcode,
            birthday: fullBirthday,
          })
        }

        if (res) {
          const customer = res?.customer
          // eslint-disable-next-line
          smartech(`contact`, 2, {
            'pk^customerid': `${customer?.id_customer}`,
            email: customer?.email,
            FIRST_NAME: customer?.firstname,
            LAST_NAME: customer?.lastname,
            mobile: netCorePhone,
            DATE_OF_BIRTH: netCoreDOB,
            POSTAL_CODE: postcode,
          })
          // eslint-disable-next-line
          smartech(`identify`, `${customer?.id_customer}`)
          // eslint-disable-next-line
          smartech(`dispatch`, `sign_up`, {
            email: customer?.email,
            first_name: customer?.firstname,
            last_name: customer?.lastname,
            mobile: netCorePhone,
            date_of_birth: netCoreDOB,
            postal_code: postcode,
          })
        }
      } else {
        const emailProviderList = await getEmailProviderListFireBase(email)

        if (emailProviderList?.length) {
          updateAuthState({
            overlayLoading: false,
            uiState: AUTH_UI_STATE.merge,
          })
          return
        }

        await logInWithPhoneGetSMSFireBase(authState.dataPhone)

        segmentPDPAConsentResponded()

        /**
         * Description: for sign up using phone number, the auto enroll loyalty program will be executed at the AuthVerify after the phone number is verified
         */
        updateAuthState({
          overlayLoading: false,
          uiState: AUTH_UI_STATE.verifyPhone,
        })
      }
    } catch (err) {
      const userData = {
        firstName,
        lastName,
        email,
      }
      handleError(err, userData)
    }
  }

  const getTextField = (fieldData) => {
    const { inputRef, label, fieldName, id, helper } = fieldData
    return (
      <TextField
        fullWidth
        inputRef={inputRef}
        label={t(label)}
        name={fieldName}
        type="text"
        htmlFor={id}
        onFocus={() => ontextFieldFocus(fieldName)}
        inputProps={{
          id,
          maxLength: 26,
        }}
        {...helperFieldProps[helper]}
      />
    )
  }

  return (
    <form
      className="auth__signup-form"
      onSubmit={handleSubmit(handleFormSubmit)}
      noValidate
    >
      <style jsx>{style}</style>
      <style jsx>{phoneStyle}</style>
      <FormItem>
        <TextField
          fullWidth
          inputRef={emailRegister}
          label={t('Email')}
          name={INPUT_NAME_EMAIL}
          type="email"
          htmlFor="email"
          onFocus={() => ontextFieldFocus(INPUT_NAME_EMAIL)}
          inputProps={{
            id: 'email',
          }}
          {...helperFieldProps.email}
          helperText={errors[INPUT_NAME_EMAIL]?.message}
        />
      </FormItem>
      <FormItem>
        {getTextField({
          inputRef: firstNameRegister,
          label: 'First Name',
          fieldName: INPUT_NAME_FIRST,
          id: 'firstname',
          helper: 'firstName',
        })}
      </FormItem>
      <FormItem>
        {getTextField({
          inputRef: lastNameRegister,
          label: 'Last Name',
          fieldName: INPUT_NAME_LAST,
          id: 'lastname',
          helper: 'lastName',
        })}
      </FormItem>
      {!isPhoneSignup && isAutoLoyaltySignupFlag && (
        <FormItem>
          <TextFieldPhone
            htmlFor="phone"
            inputProps={{
              cy: 'auth__phone__input',
              required: true,
              type: 'tel',
              id: 'phone',
            }}
            label={t('Phone Number')}
            name="phone"
            countryList={countriesPayload?.countries}
            phonePrefixRegister={register({ required: true })}
            phoneNumberRegister={phoneRegister}
            selectedPhonePrefix={String(prefix)}
            userPhoneNumber={null}
            handleChangePhonePrefix={(e) => setPrefix(+e.target.value)}
            error={errors.phone?.message}
          />
        </FormItem>
      )}
      {isAutoLoyaltySignupFlag && <AuthDateOfBirthSelect register={register} />}
      {isAutoLoyaltySignupFlag && (
        <FormItem>
          <TextField
            fullWidth
            name="postcode"
            htmlFor="postcode"
            inputProps={{
              id: 'postcode',
            }}
            label={hasLocizeTranslation(
              t,
              'ADDRESS_FORM_POSTAL_CODE',
              'Postcode',
            )}
            defaultValue={null}
            inputRef={register({ required: true })}
            error={!!errors?.postcode}
            helperText={errors?.postcode?.message}
            type="number"
            onFocus={() => ontextFieldFocus('postcode')}
          />
        </FormItem>
      )}
      {!isPhoneSignup && (
        <FormItem className="password-textfield">
          <TextFieldPassword
            inputRef={passwordRegister}
            name={INPUT_NAME_PASSWORD}
            {...helperFieldProps.password}
            passwordFormat={passwordFormat}
            applyPasswordPolicy
            onFocus={() => ontextFieldFocus(INPUT_NAME_PASSWORD)}
          />
        </FormItem>
      )}
      <AuthPDPAContent />
      <FormItem>
        <Button
          className="auth__signup-form-cta"
          cy="auth__login__email__button"
          disabled={isDisabledSubmit}
          htmlType={BUTTON_HTML_TYPE.submit}
          type={BUTTON_TYPES.primary}
        >
          {hasLocizeTranslation(
            t,
            'AUTH_AGREE_PDPA_BUTTON',
            'Agree & Create Account',
          )}
        </Button>
      </FormItem>
    </form>
  )
}

SignupForm.defaultProps = {
  countriesPayload: undefined,
  internationalization: undefined,
  mergeHandler: undefined,
}

SignupForm.propTypes = {
  countriesPayload: PropTypes.shape({
    countries: PropTypes.arrayOf(
      PropTypes.shape({
        call_prefix: PropTypes.number,
        id_country: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }),
  internationalization: PropTypes.shape({}),
  mergeHandler: PropTypes.func,
  t: PropTypes.func.isRequired,
}

export default compose(
  connect((state) => ({
    countriesPayload: state.country.payload,
    internationalization: state.internationalization,
  })),
  withI18next(),
)(SignupForm)
