import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import BackdropModal from 'components/portal/backdrop-modal'
import Dialog from 'components/common/dialog'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { withI18next } from 'lib/i18n/withI18next'
import compose from 'recompose/compose'
import Typo from 'constants/typography'
import { useFlagsmithGetFlagValue } from 'lib/flagsmith'
import Icon from 'components/common/icon'
import { ICON_SIZE } from 'components/common/icon/const'
import { connect } from 'react-redux'
import styles from './cart-loyalty-benefit-modal.scss'

const CartLoyaltyBenefitModal = ({
  t,
  isPhone,
  next_tier_color,
  next_tier_name_key,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen])
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen])

  const loyaltyData = JSON.parse(
    useFlagsmithGetFlagValue('LoyaltyLandingPageContent_20230419', 'null'),
  )?.tiers
  const nextTierLoyaltyData = loyaltyData?.find(
    (data) => data?.tier_name_key === next_tier_name_key,
  )

  return (
    <React.Fragment>
      <style jsx>{styles}</style>
      <div
        data-cy="cart-loyalty-benefit-modal__see-benefits"
        className={clsx(
          'cart-loyalty-benefit-modal__see-benefits',
          Typo.caption,
        )}
        tabIndex={0}
        role="button"
        onClick={openModal}
        onKeyDown={openModal}
      >
        {hasLocizeTranslation(t, 'SEE_BENEFITS', 'See Benefits')}
      </div>
      <BackdropModal
        zIndex={2000}
        freezeBody={isPhone}
        isOpen={isModalOpen}
        onBackdropClick={closeModal}
      >
        <Dialog
          confirmable
          confirmText={hasLocizeTranslation(t, 'OKAY', 'OKAY')}
          onClose={closeModal}
          onConfirm={closeModal}
          title={
            <div
              className={clsx(Typo.title3, 'cart-loyalty-benefit-modal__title')}
            >
              <span>
                {hasLocizeTranslation(t, nextTierLoyaltyData?.title_key)}
              </span>
              &nbsp;
              <span style={{ color: next_tier_color }}>
                {hasLocizeTranslation(t, next_tier_name_key, 'Gold')}
              </span>
            </div>
          }
        >
          <div className="cart-loyalty-benefit-modal__dialog">
            <div className={clsx(Typo.body2)}>
              {hasLocizeTranslation(t, nextTierLoyaltyData?.subtitle_key)}
            </div>
            <div className={clsx('cart-loyalty-benefit-modal__detail')}>
              {nextTierLoyaltyData?.summary_benefits?.map(
                ({ desktop_icon_url, title_key }) => (
                  <div key={title_key}>
                    <Icon
                      className="cart-loyalty-benefit-modal__icon"
                      size={ICON_SIZE.medium}
                      src={desktop_icon_url}
                    />
                    <span className={Typo.subtitle2}>
                      {hasLocizeTranslation(t, title_key)}
                    </span>
                  </div>
                ),
              )}
            </div>
          </div>
        </Dialog>
      </BackdropModal>
    </React.Fragment>
  )
}

CartLoyaltyBenefitModal.defaultProps = {
  // component props
  next_tier_color: '',
  next_tier_name_key: '',
}

CartLoyaltyBenefitModal.propTypes = {
  // component props
  t: PropTypes.func.isRequired,
  isPhone: PropTypes.bool.isRequired,
  next_tier_color: PropTypes.string,
  next_tier_name_key: PropTypes.string,
}

export default compose(
  connect((state) => ({
    isPhone: state.device.isPhone,
  })),
  withI18next(),
)(CartLoyaltyBenefitModal)
