import { SEGMENT_STORAGE_KEY_CATEGORY } from 'lib/segment/const'
import {
  MOBILE_DATA,
  DESKTOP_DATA,
  NEW_UI_DESKTOP_DATA,
  NEW_UI_MOBILE_DATA,
} from './const'

const getCategoryData = (isNewUI, isPhone) => {
  const oldUICategoryData = isPhone ? MOBILE_DATA : DESKTOP_DATA
  const newUICategoryData = isPhone ? NEW_UI_MOBILE_DATA : NEW_UI_DESKTOP_DATA

  return isNewUI ? newUICategoryData : oldUICategoryData
}

const setSegmentCategoryKeyData = (showSearch, refType) => {
  sessionStorage.setItem(
    SEGMENT_STORAGE_KEY_CATEGORY.id,
    showSearch ? '' : 'no_results',
  )
  sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.position, '')
  sessionStorage.setItem(
    SEGMENT_STORAGE_KEY_CATEGORY.type,
    refType || (showSearch ? 'error_page' : 'search'),
  )
  sessionStorage.setItem(SEGMENT_STORAGE_KEY_CATEGORY.subPos, '')
}

export { getCategoryData, setSegmentCategoryKeyData }
