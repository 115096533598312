import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import style from './style.scss'
import Icon from '../icon'

const STATUS_CIRCLE_STATES = {
  grey: 'grey',
  green: 'green',
  red: 'red',
}

const StatusCircle = ({ iconSrc, state, alt }) => (
  <div className={clsx('status-circle__wrapper', state)}>
    <style jsx>{style}</style>
    <Icon className="status-circle__icon" size={40} src={iconSrc} alt={alt} />
  </div>
)

StatusCircle.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  state: PropTypes.oneOf(Object.values(STATUS_CIRCLE_STATES)),
  alt: PropTypes.string,
}

StatusCircle.defaultProps = {
  state: STATUS_CIRCLE_STATES.grey,
  alt: undefined,
}

export { StatusCircle, STATUS_CIRCLE_STATES }
