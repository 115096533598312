import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import SearchForm from 'components/search/search-form'
import { withI18next } from 'lib/i18n/withI18next'
import { getSessionStorageOnInit } from 'lib/utils/common/commonUtils'
import { hasLocizeTranslation } from 'lib/utils/locize'
import { PDP_ERROR_MESSAGE } from '../const'
import EmptyResultSquare from '../empty-result-square'
import ErrorTitle from '../error-title'
import ErrorContainerTitle from './container-title'
import styles from './styles.scss'
import { getCategoryData } from '../utils'

const ErrorDefault = ({
  isNewUI,
  isPhone,
  refType,
  showDefaultError,
  showSearch,
  showPDPError,
  t,
}) => {
  const [searchWord, setSearchWord] = useState(
    getSessionStorageOnInit('recent-search-word'),
  )
  const categoryData = getCategoryData(isNewUI, isPhone)
  const isPageError = showDefaultError || showPDPError

  const clearSearchWord = () => {
    setSearchWord('')
  }

  return (
    <div className="error-default-wrapper">
      <style jsx>{styles}</style>
      <ErrorTitle
        showPDPError={showPDPError}
        showDefaultError={showDefaultError}
      />
      {showSearch && (
        <div className="search-form-container">
          <SearchForm
            onClose={clearSearchWord}
            placeholder={
              showPDPError
                ? hasLocizeTranslation(
                    t,
                    PDP_ERROR_MESSAGE.placeholder.key,
                    PDP_ERROR_MESSAGE.placeholder.message,
                  )
                : null
            }
            searchWord={searchWord}
            setSearchWord={setSearchWord}
          />
        </div>
      )}
      <div className="empty-results-container">
        <div className="empty-results__header">
          <ErrorContainerTitle isNewUI={isNewUI} isPageError={isPageError} />
        </div>
        <div className="empty-results-grid">
          {categoryData.map((square) => (
            <EmptyResultSquare
              isNewUI={isNewUI}
              refType={refType}
              square={square}
              showSearch={showSearch}
              key={square.label}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

ErrorDefault.defaultProps = {
  isNewUI: false,
  refType: null,
  showDefaultError: true,
  showSearch: true,
  showPDPError: undefined,
}

ErrorDefault.propTypes = {
  isNewUI: PropTypes.bool,
  isPhone: PropTypes.bool.isRequired,
  refType: PropTypes.string,
  showDefaultError: PropTypes.bool,
  showSearch: PropTypes.bool,
  showPDPError: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

const Extended = withI18next()(ErrorDefault)

export default connect((state) => ({
  isPhone: state.device.isPhone,
}))(Extended)
