import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { bindActionCreators } from 'redux'
import cartDuck from 'components/cart/duck'
import Icon, { ICON_SIZE } from 'components/common/icon'
import ICONS from 'components/common/icon/const'
import Loader from 'components/loader'
import useAddToBag from 'components/shared/useRedux/useAddToBag'
import Typo from 'constants/typography'
import ProductAPiHandler from 'lib/api/product'
import { withI18next } from 'lib/i18n/withI18next'
import StateStorage from 'lib/state-storage'
import { isSoldOut } from 'lib/utils/product'

const addToBag = async ({
  productId,
  setIsLoading,
  sizeData,
  addToBagAction,
  refData,
}) => {
  try {
    setIsLoading(true)

    // NOTE: This product details section is copied from ListView add to bag, maybe can create as a reuseable function
    const res = await ProductAPiHandler.getProduct({
      product_id: productId,
      isGuestMode: StateStorage.isGuestMode(),
    })

    if (res) {
      // Do nothing if sold out at this time, interstitial will catch this.
      // const isSoldOut = res.stock?.is_sold_out || res.stock?.quantity === 0

      addToBagAction({
        onError: () => setIsLoading(false),
        onSuccess: () => setIsLoading(false),
        product: res,
        sizeData,
        refData,
      })
    } else {
      setIsLoading(false)
    }
  } catch (err) {
    setIsLoading(false)
  }
}

const getArrow = (onClick, iconName, pageNum) => (
  <Icon
    className="option-item"
    onClick={(e) => onClick(e, pageNum)}
    src={ICONS[iconName]}
    size={ICON_SIZE.small}
  />
)

const HoverInteractionsAddToBag = ({
  addToBagAction,
  options,
  productId,
  refData,
  t,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [pageNo, setPageNo] = useState(1)

  const callSetPageNo = (e, no) => {
    if (e?.preventDefault && e?.stopPropagation) {
      e.preventDefault()
      e.stopPropagation()
    }

    setPageNo(no)
  }

  const callAddToBag = (e, sizeData, soldOut) => {
    if (e?.preventDefault && e?.stopPropagation) {
      e.preventDefault()
      e.stopPropagation()
    }

    const isOneSize = options?.length === 1

    if (isLoading || soldOut || (!sizeData && !isOneSize)) {
      return
    }

    addToBag({
      productId,
      setIsLoading,
      sizeData: isOneSize ? options[0] : sizeData,
      addToBagAction,
      refData,
    })
  }

  const ctaKey = 'Add To Bag'
  // NOTE: Came up with this logic because couldn't find products with many options + long text
  const hasLargeWidthOption = options?.length > 0 && options[0].size?.length > 3
  const showNextThreshold = hasLargeWidthOption ? 2 : 4

  return (
    <div
      className="product-hover-interactions__add-to-bag"
      onClick={callAddToBag}
      onKeyUp={callAddToBag}
      role="button"
      tabIndex={0}
    >
      {isLoading ? (
        <Loader small />
      ) : (
        <React.Fragment>
          {options?.length > 1 && (
            <div className="option-items-wrapper">
              {pageNo === 2 && getArrow(callSetPageNo, 'arrowHeadLeft', 1)}
              {options.map((option, idx) => {
                if (
                  (pageNo === 1 && idx > showNextThreshold - 1) ||
                  (pageNo === 2 && idx < showNextThreshold)
                ) {
                  return null
                }

                const soldOut = isSoldOut(option)

                return (
                  <button
                    className={clsx('option-item', Typo.button, {
                      'sold-out': soldOut,
                    })}
                    key={option.size}
                    onClick={(e) => callAddToBag(e, option, soldOut)}
                    style={{ width: hasLargeWidthOption ? '40%' : '20%' }}
                  >
                    {option.size}
                  </button>
                )
              })}
              {pageNo === 1 &&
                options.length > showNextThreshold &&
                getArrow(callSetPageNo, 'arrowHeadRight', 2)}
            </div>
          )}
          <span className={Typo.button}>{t(ctaKey)}</span>
        </React.Fragment>
      )}
    </div>
  )
}

HoverInteractionsAddToBag.defaultProps = {
  options: null,
  refData: null,
}

HoverInteractionsAddToBag.propTypes = {
  addToBagAction: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      size: PropTypes.string,
    }),
  ),
  productId: PropTypes.number.isRequired,
  refData: PropTypes.shape({}),
  t: PropTypes.func.isRequired,
}

export default compose(
  connect(null, (dispatch) =>
    bindActionCreators(
      {
        addToBagAction: useAddToBag,
        getCart: cartDuck.creators.get,
      },
      dispatch,
    ),
  ),
  withI18next(),
)(HoverInteractionsAddToBag)
