import { request } from './index'
import { HTTP_METHODS } from './const'
import StateStorage from '../state-storage'

class Voucher {
  static async getVoucher(noCacheflagValue) {
    const lang = StateStorage.getLanguage()
    const shop = StateStorage.getShop()
    const keyName = `pomeloVoucher`
    const localStateKey = `${keyName}-${shop}-${lang}`
    const cached = StateStorage.getLocalState(localStateKey)

    if (cached !== undefined && cached !== null && !noCacheflagValue) {
      const res = Promise.resolve(cached)
      return res
    }

    const res = await request({
      url: 'v6/vouchers',
      method: HTTP_METHODS.get,
    })

    if (res.vouchers && !noCacheflagValue) {
      StateStorage.saveLocalState(localStateKey, res, 1800)
    }

    return res
  }
}

export default Voucher
