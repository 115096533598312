import PropTypes from 'prop-types'
import React from 'react'
import clsx from 'clsx'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import Typo from 'constants/typography'

const I18nItem = ({ changeShopLang, data, isSelected }) => {
  const underlines = ['global-en']

  return (
    <React.Fragment key={data.name}>
      {underlines.includes(`${data.shop}-${data.lang}`) && (
        <hr className="i18n__line" />
      )}
      <li className="i18n__item">
        <a
          role="button"
          tabIndex="0"
          onClick={(e) => changeShopLang(e, data)}
          onKeyDown={(e) => changeShopLang(e, data)}
        >
          <Icon src={ICONS[data.shop]} size={ICON_SIZE.medium} />
          <span className={clsx('i18n__menu', Typo.subtitle2)}>
            {data.name}
          </span>
          {isSelected(data) && (
            <Icon
              className="icon-checked-new"
              src={ICONS.waitListed}
              size={ICON_SIZE.medium}
            />
          )}
        </a>
      </li>
    </React.Fragment>
  )
}

I18nItem.propTypes = {
  changeShopLang: PropTypes.func.isRequired,
  data: PropTypes.shape({
    lang: PropTypes.string,
    name: PropTypes.string,
    shop: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.func.isRequired,
}

export default I18nItem
