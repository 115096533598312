import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Typo from 'constants/typography'

const ProductItemPriceSection = ({
  discountedPriceFormatted,
  displayPrice,
  onClick,
  priceFormatted,
  productId,
  productUrl,
}) => {
  const isShowDiscountedPrice =
    discountedPriceFormatted && discountedPriceFormatted !== priceFormatted

  return (
    <div className="product_price_wishlist__container">
      {displayPrice && (
        <a href={productUrl} className="product_price__link" onClick={onClick}>
          {isShowDiscountedPrice ? (
            <div className="product_price__wrapper with-discount">
              <span className={clsx('discounted__price', Typo.price1)}>
                {discountedPriceFormatted}
              </span>
              <span
                className={clsx(
                  'full__price',
                  Typo.price2,
                  'with-discounted__price',
                )}
                data-cy={`product_item_price_${productId}__discount`}
              >
                {priceFormatted}
              </span>
            </div>
          ) : (
            <span
              className={clsx(
                'product_price__wrapper',
                'without-discount',
                Typo.price1,
              )}
              data-cy={`product_item_price_${productId}`}
            >
              {priceFormatted}
            </span>
          )}
        </a>
      )}
    </div>
  )
}

ProductItemPriceSection.defaultProps = {
  discountedPriceFormatted: '',
  displayPrice: false,
  onClick: () => null,
  priceFormatted: '',
  productId: 0,
  productUrl: '',
}

ProductItemPriceSection.propTypes = {
  discountedPriceFormatted: PropTypes.string,
  displayPrice: PropTypes.bool,
  onClick: PropTypes.func,
  priceFormatted: PropTypes.string,
  productId: PropTypes.number,
  productUrl: PropTypes.string,
}

export default ProductItemPriceSection
