import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withI18next } from 'lib/i18n/withI18next'
import ResponsiveImage from 'components/images/responsive-image'
import env from 'config/env'
import Typo from 'constants/typography'
import Badge3D from 'components/pdp/carousel-images/badge-3d/Badge3D'
import Icon, { ICONS, ICON_SIZE } from 'components/common/icon'
import ProductItemHoverInteractions from './hover-interactions'
import ProductPremiumTags from './product-premium-tags/ProductPremiumTags'

function getImgPath(src) {
  let originSrc = src
  if (src?.charAt && src.charAt(0) === '/') {
    originSrc = src.substr(1, src.length - 1)
  }

  return `${env.IMG_HOST}${originSrc}`
}

const ProductItemFigure = ({
  className,
  coverSrc,
  cy,
  discountBadge,
  displayHoverInteractions,
  displayPremiumTags,
  displayFlashSaleTitle,
  goToSimilarProducts,
  hoverSrc,
  is3dImageAvailable,
  isBackInStock,
  isBeautyProduct,
  isListView,
  isLowStock,
  isSoldOut,
  isComingSoon,
  isFlashSale,
  flash_sale_seconds_left,
  comingSoonText,
  name,
  onClick,
  options,
  productId,
  productUrl,
  refType,
  stockQuantity,
  t,
}) => {
  const imgProps = {
    alt: name,
    bigDesktop: '18vw',
    desktop: '18vw',
    imgRatio: 1.33,
    laptop: '18vw',
    phone: '45vw',
    tablet: '18vw',
  }

  const isShowFlashSaleIcon = isFlashSale && !isSoldOut

  const hasDisplayHoverInteractionsOrPremiumTags =
    displayHoverInteractions || displayPremiumTags || is3dImageAvailable

  return (
    <a
      href={productUrl}
      className={clsx('product__link', { 'left-block': isListView })}
      onClick={onClick}
      data-cy={cy}
    >
      <figure className={clsx('product-image__fig', className || '')}>
        {!!coverSrc && (
          <div className="product-image__cover">
            <ResponsiveImage {...imgProps} src={getImgPath(coverSrc)} />
          </div>
        )}
        {!!hoverSrc && (
          <div className="product-image__hover">
            <ResponsiveImage {...imgProps} src={getImgPath(hoverSrc)} />
          </div>
        )}
        {!!discountBadge && (
          <div
            className="reduction-label"
            data-cy={cy ? `${cy}__reduction` : undefined}
          >
            <span>
              <span className={Typo.price2}>{`-${discountBadge}`}</span>
              {isShowFlashSaleIcon && (
                <Icon src={ICONS.flashSale} size={ICON_SIZE.extraSmall} />
              )}
            </span>
          </div>
        )}

        {!!hasDisplayHoverInteractionsOrPremiumTags && (
          <div className="product-image__fig__label">
            {displayHoverInteractions && (
              <ProductItemHoverInteractions
                goToSimilarProducts={goToSimilarProducts}
                options={options}
                productId={productId}
                refType={refType}
                showAddToBag={!isSoldOut}
                showSeeSimilar={!isBeautyProduct}
              />
            )}
            {is3dImageAvailable && (
              <div className="three-d-label">
                <Badge3D />
              </div>
            )}
            {displayPremiumTags && (
              <ProductPremiumTags
                t={t}
                cy={cy}
                isComingSoon={isComingSoon}
                comingSoonText={comingSoonText}
                isSoldOut={isSoldOut}
                isFlashSale={isFlashSale}
                flashSaleSecondsLeft={flash_sale_seconds_left}
                displayFlashSaleTitle={displayFlashSaleTitle}
                isLowStock={isLowStock}
                stockQuantity={stockQuantity}
                isBackInStock={isBackInStock}
              />
            )}
          </div>
        )}
      </figure>
    </a>
  )
}

ProductItemFigure.defaultProps = {
  className: '',
  coverSrc: '',
  cy: undefined,
  discountBadge: '',
  displayHoverInteractions: false,
  displayPremiumTags: true,
  displayFlashSaleTitle: true,
  hoverSrc: '',
  is3dImageAvailable: false,
  isBackInStock: false,
  isBeautyProduct: false,
  isListView: false,
  isLowStock: false,
  isSoldOut: false,
  isComingSoon: false,
  isFlashSale: false,
  flash_sale_seconds_left: 0,
  comingSoonText: '',
  name: '',
  onClick: undefined,
  options: null,
  productUrl: '',
  refType: null,
  stockQuantity: 0,
}

ProductItemFigure.propTypes = {
  className: PropTypes.string,
  coverSrc: PropTypes.string,
  cy: PropTypes.string,
  discountBadge: PropTypes.string,
  displayHoverInteractions: PropTypes.bool,
  displayPremiumTags: PropTypes.bool,
  displayFlashSaleTitle: PropTypes.bool,
  goToSimilarProducts: PropTypes.func.isRequired,
  hoverSrc: PropTypes.string,
  isBackInStock: PropTypes.bool,
  is3dImageAvailable: PropTypes.bool,
  isBeautyProduct: PropTypes.bool,
  isListView: PropTypes.bool,
  isLowStock: PropTypes.bool,
  isSoldOut: PropTypes.bool,
  isComingSoon: PropTypes.bool,
  isFlashSale: PropTypes.bool,
  flash_sale_seconds_left: PropTypes.number,
  comingSoonText: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  productId: PropTypes.number.isRequired,
  productUrl: PropTypes.string,
  stockQuantity: PropTypes.number,
  refType: PropTypes.string,
  t: PropTypes.func.isRequired,
}

export { ProductItemFigure }

const Extended = withI18next()(ProductItemFigure)

export default Extended
