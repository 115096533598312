import PropTypes from 'prop-types'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import Router from 'next/router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ShopLanguageMap from 'lib/i18n/shop-language-map'
import StateStorage from 'lib/state-storage'
import UrlUtil from 'lib/utils/url'
import userDuck from 'components/user/account-details/duck'
import { segmentLanguageSelected } from 'lib/segment'
import { clearRecentlyViewProducts } from 'components/recently-view/utils'
import { SHOP_MENUS } from 'constants/shop-language'
import { useFlagsmithGetFlagValue } from 'lib/flagsmith'
import I18nItem from '../i18n-item'
import styles from './style.scss'

const updateI18n = (shopSlug, langSlug) => {
  let lang = langSlug

  if (shopSlug === 'th' && langSlug === 'en') {
    lang = `${langSlug}-${shopSlug}`
  }

  StateStorage.saveShop(shopSlug)
  StateStorage.saveLanguage(lang)
  const asPath = UrlUtil.getPathWithoutShopAndLang(Router.asPath) || '/'
  window.location.href = `/${shopSlug}/${langSlug}${asPath}`
}

const updateTheShop = ({
  user,
  idShop,
  shop,
  lang,
  invalidateUser,
  handleUserInfo,
}) => {
  if (user) {
    // Update user's country id on database
    const idLang = ShopLanguageMap.getIdLangBySlugAndLang(shop, lang)
    invalidateUser()
    handleUserInfo({
      type: 'UPDATE_INFO',
      userId: user.id_customer,
      id_shop: idShop,
      id_lang: idLang,
    })
  }
}

const sendSelectedLangToSegment = (idShop, lang, shop, user) => {
  const isoCode = ShopLanguageMap.getISOCodeBySlugAndLang(shop, lang)
  const promptLocation = user ? 'account' : 'nav_sign_up'
  segmentLanguageSelected(isoCode, idShop, promptLocation)
}

const saveEmptyCountry = (shop) => {
  if (shop === 'global') {
    StateStorage.saveCountry('')
  }
}

const I18nTooltip = ({
  handleUserInfo,
  internationalization: { country, language },
  invalidateUser,
  user,
}) => {
  const changeShopLang = (e, { shop, lang }) => {
    e?.preventDefault()
    e?.stopPropagation()
    clearRecentlyViewProducts(shop)

    const idShop = ShopLanguageMap.getIdShopBySlug(shop)
    sendSelectedLangToSegment(idShop, lang, shop, user)
    saveEmptyCountry(shop)

    updateTheShop({
      user,
      idShop,
      shop,
      lang,
      invalidateUser,
      handleUserInfo,
    })
    updateI18n(shop, lang)
  }

  const isSelected = ({ shop, lang }) => shop === country && lang === language

  const isEnableCambodianShop = useFlagsmithGetFlagValue(
    'EnableCambodianShop_20240618',
    true,
  )
  const shopMenus = isEnableCambodianShop
    ? SHOP_MENUS
    : SHOP_MENUS.filter((menu) => menu.shop !== 'kh')

  return (
    <ReactTooltip
      uuid="i18n-tooltip"
      class="tooltip"
      effect="solid"
      id="i18n"
      offset={{ top: 10 }}
      place="bottom"
      type="light"
    >
      <style jsx>{styles}</style>
      <ul className="i18n__tooltip">
        {shopMenus.map((data) => (
          <I18nItem
            key={data.order}
            changeShopLang={changeShopLang}
            data={data}
            isSelected={isSelected}
          />
        ))}
      </ul>
    </ReactTooltip>
  )
}

I18nTooltip.defaultProps = {
  user: null,
}

I18nTooltip.propTypes = {
  internationalization: PropTypes.shape({
    country: PropTypes.string,
    language: PropTypes.string,
  }).isRequired,
  invalidateUser: PropTypes.func.isRequired,
  handleUserInfo: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id_customer: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
}

export default connect(
  (state) => ({
    tabs: state.tabs,
    login: state.login,
    internationalization: state.internationalization,
    user: state.auth.user,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        invalidateUser: userDuck.creators.invalidate,
        handleUserInfo: userDuck.creators.get,
      },
      dispatch,
    ),
)(I18nTooltip)
