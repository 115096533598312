import Link from 'next/link'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import PaymentApiHandler from 'lib/api/payment'
import Typo from 'constants/typography'
import { customFlagValue } from 'lib/utils/handle-flag/const'
import FooterUsp from '../usp/footer-usp'
import {
  FOOTER_LOCIZE,
  FOOTER_INFO,
  FOOTER_SECTION,
  FOOTER_LINKS,
} from './const'
import styles from './style.scss'
import { getAllowedFooterInfoByShop } from './FooterHook'
import ContactSection from './contact-section'
import FooterBandSection from './band-section'

const Footer = ({
  internationalization: { country, language, shop },
  pageName,
  noFooterTopMargin,
  t,
}) => {
  const allowedFooter = getAllowedFooterInfoByShop(FOOTER_INFO, shop)
  const isCategoryPage = pageName === 'category'
  const [isHoolahActive, setIsHoolahActive] = useState(false)
  const hoolahFlagValue = customFlagValue({ defaultValue: false }) // TODO: handle with flagsmith ('InstallmentsPreCheckout-20220627' is old flag name)
  const isHoolahFooterUspVisible = Boolean(hoolahFlagValue && isHoolahActive)

  useEffect(() => {
    if (hoolahFlagValue) {
      PaymentApiHandler.getPaymentMethods().then((result) => {
        const isHoolahAvailable = result?.payment_methods?.find(
          (option) => option.type === 'hoolah',
        )
        setIsHoolahActive(!!isHoolahAvailable)
      })
    }
  }, [hoolahFlagValue])

  const getFooterContent = (content) => (
    <ul className="footer-content">
      {content.map(({ key, href, as, label, rel }) => (
        <li key={key} data-cy={`footer_${key}`}>
          <Link href={href} as={as && `/${country}/${language}${as}`}>
            <a rel={rel || 'nofollow'} className={Typo.body2}>
              {t(label)}
            </a>
          </Link>
        </li>
      ))}
    </ul>
  )

  const getFooterSection = (name, content) => {
    let footerContent = content
    if (name.key === FOOTER_LOCIZE.info_key) {
      footerContent = allowedFooter
    }

    if (name.key === FOOTER_LOCIZE.contact_key) {
      return (
        <ContactSection
          key={name.key}
          isHoolahVisible={isHoolahFooterUspVisible}
        />
      )
    }

    return (
      <div key={name.key} className="footer-section">
        <span
          className={clsx('footer-title', Typo.subtitle2)}
          data-cy={name.key.toLowerCase()}
        >
          {hasLocizeTranslation(t, name.key, name.desc)}
        </span>
        {name.key === FOOTER_LOCIZE.brand_key ? (
          <FooterBandSection footerContent={footerContent} />
        ) : (
          getFooterContent(footerContent)
        )}
      </div>
    )
  }

  return (
    <footer>
      <style jsx>{styles}</style>
      <FooterUsp
        isCategoryPage={isCategoryPage}
        isHoolahActive={isHoolahFooterUspVisible}
        noFooterTopMargin={noFooterTopMargin}
      />
      <section className="container">
        {FOOTER_SECTION.map((item) => {
          const { name, content } = item
          return getFooterSection(name, content)
        })}
      </section>
      <section className="container">
        <div className="footer-last">
          <div className="footer-credits" data-cy="footer_credits">
            <Link href="/home" as={`/${country}/${language}/`}>
              <a className={Typo.caption}>
                &copy;&nbsp;{new Date().getFullYear()} Pomelofashion.com
              </a>
            </Link>
          </div>
          <div className="footer-last-row">
            {FOOTER_LINKS.map(({ key, href, as, label }, idx) => (
              <div key={key} className="link-list">
                <Link href={href} as={`/${country}/${language}${as}`}>
                  <a className={Typo.caption}>
                    {hasLocizeTranslation(t, label.key, label.text)}
                  </a>
                </Link>
                {idx <= 1 && <span className="link-pipe">|</span>}
              </div>
            ))}
          </div>
        </div>
      </section>
    </footer>
  )
}

Footer.defaultProps = {
  pageName: '',
  noFooterTopMargin: false,
}

Footer.propTypes = {
  internationalization: PropTypes.shape({
    country: PropTypes.string,
    language: PropTypes.string,
    shop: PropTypes.number,
  }).isRequired,
  pageName: PropTypes.string,
  noFooterTopMargin: PropTypes.bool,
  t: PropTypes.func.isRequired,
}

export default compose(
  connect((state) => ({
    internationalization: state.internationalization,
    isPhone: state.device.isPhone,
  })),
  withI18next(),
)(Footer)
