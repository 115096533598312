import clsx from 'clsx'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import PropTypes from 'prop-types'
import React, { useRef, useCallback } from 'react'
import Typo from 'constants/typography'
import Coachmark from 'components/common/coachmark'
import { COACHMARK_POSITION } from 'components/common/coachmark/const'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import {
  VOUCHER_CHECKOUT_LOCIZE,
  LOGO_BADGE,
} from 'components/pdp/carousel-images/voucher/voucher-checkout/const'
import VoucherBadge from 'components/pdp/carousel-images/voucher/voucher-badge'
import VoucherButton from 'components/pdp/carousel-images/voucher/voucher-button'
import {
  getVoucherDesc,
  getVoucherTitle,
} from 'components/pdp/carousel-images/voucher/utils'
import {
  isPartnerVoucher,
  isVoucherWithBadge,
} from 'components/pdp/carousel-images/voucher/voucher-checkout/utils'
import useModal from 'components/portal/useModal'
import { withI18next } from 'lib/i18n/withI18next'
import { hasLocizeTranslation } from 'lib/utils/locize'
import VoucherTncModal from '../voucher-tnc-modal'
import { VOUCHER_DISCOUNT_TYPE, VOUCHER_LOCIZE } from '../const'

const VoucherPiece = ({
  copyCode,
  isCoachMarkPresent,
  list,
  saveCoachMarkClosed,
  showCoachMark,
  t,
  voucherPieceRef,
  isCheckout,
  onVoucherSelected,
  appliedVoucher,
}) => {
  const {
    badge_type,
    code,
    condition: {
      discount: {
        reduction_amount_formatted,
        reduction_percent_formatted,
        type,
      },
      minimum_items,
    },
    custom_badge_text,
    hot_deal,
    logo_badge_cdn,
    terms_conditions,
    valid_end_date,
    voucher_type,
    is_applicable,
  } = list
  const copyButtonRef = useRef(null)
  const [isModalOpen, setIsModalOpen] = useModal(false)
  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen])
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen])
  const reductionQuantity =
    type === VOUCHER_DISCOUNT_TYPE.AMOUNT
      ? reduction_amount_formatted
      : reduction_percent_formatted

  dayjs.extend(utc)
  const validDate = dayjs.utc(valid_end_date).format('DD/MM/YY HH:mm')

  const isNotApplicable = !is_applicable && isCheckout

  const shouldShowTNC = terms_conditions && isCheckout

  const isBadgeShow = isVoucherWithBadge(
    hot_deal,
    badge_type,
    custom_badge_text,
  )

  const isLogoShow = isPartnerVoucher(voucher_type) && badge_type === LOGO_BADGE
  const isBadgeVisible = isBadgeShow || isLogoShow

  const shouldShowCoachMark = showCoachMark && isCoachMarkPresent && !isCheckout
  const coachMarkContainerProps = {
    className: 'pdp__voucher-modal__piece-coachmark',
    style: {
      left: 0,
      top: '-30px',
      whiteSpace: 'break-spaces',
      width: '100%',
    },
  }

  return (
    <React.Fragment>
      <div className="pdp__voucher-modal__piece" ref={voucherPieceRef}>
        <div
          className={clsx('pdp__voucher-modal__piece-info', {
            'non-eligible': isNotApplicable,
          })}
        >
          <span className={Typo.subtitle1}>
            {getVoucherTitle(reductionQuantity, code, type, minimum_items, t)}
          </span>
          <span
            className={clsx(Typo.body2, 'pdp__voucher-modal__piece-info-desc')}
          >
            {getVoucherDesc(isNotApplicable, list, t)}
          </span>
          <span
            className={clsx(
              Typo.caption,
              'pdp__voucher-modal__piece-info-date',
              {
                hidden: !valid_end_date,
              },
            )}
          >
            {hasLocizeTranslation(
              t,
              VOUCHER_CHECKOUT_LOCIZE.VOUCHER_CHECKOUT_VALID_DATE.key,
              VOUCHER_CHECKOUT_LOCIZE.VOUCHER_CHECKOUT_VALID_DATE.text,
            ).replace(/%s/g, validDate)}
            {shouldShowTNC && (
              <Icon
                className="pdp__voucher-modal__piece-info-icon"
                alt="voucher-tnc-info"
                src={ICONS.grayInfo}
                size={ICON_SIZE.small}
                onClick={openModal}
              />
            )}
          </span>
          <div
            className={clsx('pdp__voucher-modal__piece-info-bottom', {
              tnc: isCheckout,
              badge: isBadgeVisible,
            })}
          >
            {isBadgeVisible && (
              <VoucherBadge
                isHotDeal={hot_deal}
                text={custom_badge_text}
                isLogoShow={isLogoShow}
                logoUrl={logo_badge_cdn}
                isNotApplicable={isNotApplicable}
              />
            )}
            <VoucherButton
              appliedVoucher={appliedVoucher}
              code={code}
              copyButtonRef={copyButtonRef}
              copyCode={copyCode}
              isCheckout={isCheckout}
              isNotApplicable={isNotApplicable}
              onVoucherSelected={onVoucherSelected}
            />
          </div>
        </div>
      </div>
      {shouldShowCoachMark && (
        <Coachmark
          onClose={saveCoachMarkClosed}
          position={COACHMARK_POSITION.TOP_RIGHT}
          text={hasLocizeTranslation(
            t,
            VOUCHER_LOCIZE.VOUCHER_COACH_MARK_TEXT.key,
            VOUCHER_LOCIZE.VOUCHER_COACH_MARK_TEXT.text,
          )}
          isOpen={shouldShowCoachMark}
          customContainerProps={coachMarkContainerProps}
          disableLeaveListener
          disableEnterListener
          customChildrenRef={copyButtonRef}
        />
      )}
      {shouldShowTNC && (
        <VoucherTncModal
          isShow={isModalOpen}
          setIsShow={closeModal}
          terms={terms_conditions}
        />
      )}
    </React.Fragment>
  )
}

VoucherPiece.propTypes = {
  copyCode: PropTypes.func.isRequired,
  isCoachMarkPresent: PropTypes.bool.isRequired,
  list: PropTypes.shape({
    badge_type: PropTypes.string,
    code: PropTypes.string,
    condition: PropTypes.shape({
      discount: PropTypes.shape({
        reduction_amount_formatted: PropTypes.string,
        reduction_percent_formatted: PropTypes.string,
        type: PropTypes.string,
      }),
      minimum_amount_formatted: PropTypes.string,
      maximum_discount_amount_formatted: PropTypes.string,
      minimum_items: PropTypes.number,
    }),
    custom_badge_text: PropTypes.string,
    hot_deal: PropTypes.bool,
    logo_badge_cdn: PropTypes.string,
    terms_conditions: PropTypes.string,
    valid_end_date: PropTypes.string,
    voucher_type: PropTypes.string,
    is_applicable: PropTypes.number,
    is_applied: PropTypes.bool,
  }).isRequired,
  saveCoachMarkClosed: PropTypes.func.isRequired,
  showCoachMark: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  voucherPieceRef: PropTypes.shape({
    current: PropTypes.shape({}),
  }).isRequired,
  isCheckout: PropTypes.bool.isRequired,
  onVoucherSelected: PropTypes.func.isRequired,
  appliedVoucher: PropTypes.string.isRequired,
}

export default withI18next()(VoucherPiece)
