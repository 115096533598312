import Router from 'next/router'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import clsx from 'clsx'
import authDuck from 'components/auth/duck'
import checkoutDuck from 'components/checkout/duck'
import PomeloPinsConstant from 'components/checkout/pomelo-pins/const'
import Icon from 'components/common/icon'
import ICONS, { ICON_SIZE } from 'components/common/icon/const'
import ordersDuck from 'components/order-list/duck'
import { ADD_TO_BAG_API_CODE } from 'components/shared/useRedux/useAddToBag'
import Typo from 'constants/typography'
import AddressApiHandler from 'lib/api/address'
import CarrierApiHandler from 'lib/api/carrier'
import cartBadgeDuck from 'lib/api/cart-badge'
import OrderApiHandler from 'lib/api/order'
import PaymentApiHandler from 'lib/api/payment'
import { withI18next } from 'lib/i18n/withI18next'
import { segmentSignInViewed } from 'lib/segment'
import { EVENT, SEGMENT_STORAGE_KEY_PROMPT_LOCATION } from 'lib/segment/const'
import StateStorage from 'lib/state-storage'
import Tracking from 'lib/tracking'
import { toggleBodyFreezing, getAuthStatus } from 'lib/utils/common/commonUtils'
import { customFlagValue } from 'lib/utils/handle-flag/const'
import {
  CHECKOUT_STEPS,
  CHECKOUT_SUB_STEPS,
  getShippingIdRedux,
} from 'lib/utils/checkout/checkoutUtils'
import { getShopInfo } from 'lib/utils/shop-lang'
import Loader from '../loader'
import modalDuck from '../modal/duck'
import CartGenericError from './cart-generic-error'
import cartItemsDuck from './cart-items/duck'
import CartMainBody from './cart-main-body'
import CartWishlistIcon from './cart-wishlist-icon'
import { allowedOrderTypeId, DEBOUNCE_TIME, paymentTypeArray } from './const'
import cartDuck from './duck'
import { CART_FREE_GIFT_MODAL_ID } from './free-gift/const'
import styles from './style.scss'
import { getSoldOutProducts, trackCartViewed } from './utils'

// will be refactored soon
const Cart = ({
  applyStoreCredit,
  auth,
  cartData,
  cartError,
  cartLoading,
  cartProducts,
  cartQuantity,
  checkSoldOutItem,
  getCart,
  getCartQuantity,
  getOrderList,
  invalidateCartBadge,
  internationalization: { country, currency, language, locale, shop },
  isPhone,
  orderList,
  resetOrderList,
  setSuccessfulLoginRedirectUrl,
  showFallbackModal,
  showFreeGift,
  t,
  updateCart,
  updatePayment,
  updateShippingAddress,
  updateShippingMethod,
  updateShowLogin,
}) => {
  const [isDuringTimeout, setIsDuringTimeout] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const [, setForceRender] = useState({})
  const [apiError, setApiError] = useState()
  const isUnmounted = useRef(false)
  const isPreviousCartOpened = useRef(false)
  const prevCartProducts = useRef(null)
  const [orderDetail, setOrderDetail] = useState()
  const [paymentOptions, setPaymentOptions] = useState(null)
  const isCheckoutClick = useRef(false)

  const defaultCheckoutInfoFlagValue = customFlagValue({ defaultValue: false }) // TODO: force value to handle with flagsmith later (DefaultCheckout-20220830)
  const hoolahFlagValue = customFlagValue({ defaultValue: false }) // TODO: force value to handle with flagsmith later (InstallmentsPreCheckout-20220627)

  useEffect(() => {
    setIsMounted(true)
    getCartQuantity()

    return () => {
      isUnmounted.current = true
      toggleBodyFreezing(false)
    }
  }, [getCartQuantity])

  useEffect(() => {
    if (cartQuantity && cartData.cartOpen) {
      getCart({
        type: 'GET',
        body: {
          is_store_credits_applied: applyStoreCredit,
        },
      })
    }

    if (!isPreviousCartOpened.current && cartData.cartOpen) {
      trackCartViewed(cartProducts, country, shop, EVENT.cartViewed)

      if (isPhone) {
        toggleBodyFreezing(true)
      }

      setForceRender({})
    } else if (isPhone && isPreviousCartOpened.current && !cartData.cartOpen) {
      toggleBodyFreezing(false)
    }

    isPreviousCartOpened.current = cartData.cartOpen
  }, [
    cartQuantity,
    getCart,
    applyStoreCredit,
    cartData.cartOpen,
    cartProducts,
    country,
    isPhone,
    shop,
  ])

  useEffect(() => {
    // check sold out
    const soldOutProduct = getSoldOutProducts(cartProducts)
    checkSoldOutItem({
      hasSoldOutItem: soldOutProduct?.length > 0 || false,
    })
  }, [cartProducts, checkSoldOutItem])

  useEffect(() => {
    if (
      JSON.stringify(cartProducts) !== JSON.stringify(prevCartProducts.current)
    ) {
      prevCartProducts.current = cartProducts
      // When add new item to cart, fetch new quantity
      invalidateCartBadge()
      getCartQuantity()
    }
  }, [cartProducts, getCartQuantity, invalidateCartBadge])

  // fetch order list
  useEffect(() => {
    if (!auth.isGuestMode) {
      resetOrderList()
      getOrderList({
        shopId: shop,
        langId: locale,
        currencyId: currency,
      })
    }
  }, [auth.isGuestMode, currency, shop, locale, getOrderList, resetOrderList])

  // fetch order detail of the latest order from the order list
  useEffect(() => {
    if (orderList?.orders?.length) {
      const latestOrderId = orderList.orders[0].id_order
      OrderApiHandler.getItem({
        orderId: latestOrderId,
        is_offline: orderList.orders[0].is_offline,
      }).then((res) => setOrderDetail(res))
    }
  }, [orderList])

  const isEmptyCart = !!(cartQuantity?.total_items.length === 0)
  const shouldGetPaymentOptions =
    hoolahFlagValue &&
    !paymentOptions &&
    !isEmptyCart &&
    !isCheckoutClick.current
  const getAvailablePaymentOptions = () => {
    PaymentApiHandler.getPaymentMethods()
      .then(({ payment_methods }) => {
        setPaymentOptions(payment_methods)
      })
      .catch(() => setPaymentOptions(null))
  }

  // fetch payment options
  useEffect(() => {
    if (shouldGetPaymentOptions) {
      getAvailablePaymentOptions()
    }
  }, [isEmptyCart, paymentOptions, shouldGetPaymentOptions])

  const updateShippingData = (address, shippingType) => {
    const shippingId = getShippingIdRedux(address)
    const optionsCarrier = {
      zoneId: address.id_zone || address.country?.id_zone,
      productsTotal: cartProducts?.summary?.total || 0,
      customerId: auth?.user?.id_customer,
      stateId: address.id_state,
      pickupLocId: shippingId,
      shippingType,
    }
    const isShippingDataSet = CarrierApiHandler.get(optionsCarrier).then(
      (res) => {
        // no shipping methods available
        if (!res?.shipping_methods?.length) {
          return null
        }

        updateShippingMethod({ shippingMethod: res.shipping_methods[0] })
        updateShippingAddress({ shippingAddress: address })
        return true
      },
    )

    return isShippingDataSet
  }

  const updateDataByType = (type) => {
    const paymentMethod = {
      method: CHECKOUT_SUB_STEPS[type].id,
      type: CHECKOUT_SUB_STEPS[type].id,
      name: CHECKOUT_SUB_STEPS[type].title,
    }
    updatePayment({ paymentMethod })
    return true
  }

  const updateCreditCardMethod = () => {
    if (shop === 5) {
      return updateDataByType('AWAIT_PAYMENT')
    }

    const isCreditCardDataSet = PaymentApiHandler.getCreditCards().then(
      (creditCardRes) => {
        // no credit card available
        if (!creditCardRes?.credit_cards?.length) {
          return updateDataByType('AWAIT_PAYMENT')
        }

        const defaultCreditCard = creditCardRes.credit_cards.find(
          (cc) => cc.is_default,
        )

        // let the user choose the payment method when no default CC present
        if (!defaultCreditCard) {
          return updateDataByType('AWAIT_PAYMENT')
        }

        const paymentMethodData = {
          ...defaultCreditCard,
          method: 'CREDIT_CARD',
        }
        updatePayment({ paymentMethod: paymentMethodData })
        return true
      },
    )

    return isCreditCardDataSet
  }

  const updateBankTransferMethod = (
    paymentMethods,
    bankTransferType,
    bankType,
  ) => {
    const [matchBank] = paymentMethods.filter(
      (method) => method.type === bankTransferType,
    )
    // no matched bank payment method
    if (!matchBank) {
      return updateDataByType('AWAIT_PAYMENT')
    }

    const [subBankData] = matchBank.children.filter((subBankMethod) =>
      subBankMethod.description_1.toLowerCase().includes(bankType),
    )
    // no matched bank payment sub method
    if (!subBankData) {
      return updateDataByType('AWAIT_PAYMENT')
    }

    const bankPaymentMethodData = {
      method: subBankData.type.toUpperCase(),
      type: subBankData.type,
      name: subBankData.description_1,
    }
    updatePayment({ paymentMethod: bankPaymentMethodData })
    return true
  }

  const extractPaymentType = (paymentType) => {
    const [matchPaymentType] = paymentTypeArray.filter((type) =>
      paymentType?.toLowerCase().includes(type),
    )

    let bankLainnyaType
    if (!matchPaymentType && paymentType?.includes('Bank')) {
      bankLainnyaType = 'lainnya'
    }

    return matchPaymentType || bankLainnyaType
  }

  const getBankTransferData = (address, shippingType, method) => {
    const shippingId = getShippingIdRedux(address)
    const options = {
      shipping_id: shippingId,
      shipping_type: shippingType,
    }
    const isBankTransferDataSet = PaymentApiHandler.getPaymentMethods(
      options,
    ).then((paymentMethodRes) => {
      const { payment_methods } = paymentMethodRes

      // no payment methods available
      if (!payment_methods?.length) {
        return updateDataByType('AWAIT_PAYMENT')
      }

      switch (method) {
        case 'bca':
          return updateBankTransferMethod(
            payment_methods,
            'vt_bank_transfer',
            method,
          )
        case 'mandiri':
          return updateBankTransferMethod(
            payment_methods,
            'vt_bank_transfer',
            method,
          )
        case 'lainnya':
          return updateBankTransferMethod(
            payment_methods,
            'vt_bank_transfer',
            method,
          )
        case 'bni':
        default:
          return updateBankTransferMethod(
            payment_methods,
            'mt_bank_transfer',
            method,
          )
      }
    })

    return isBankTransferDataSet
  }

  const updatePaymentData = (address, shippingType) => {
    // when there is no matched payment type
    // will update paymentMethod data as AWAIT_PAYMENT
    // so we can still skip the shipping selection step
    const paymentType = extractPaymentType(orderDetail?.payment.type)

    switch (paymentType) {
      case 'cod':
        return updateDataByType('CASH_ON_DELIVERY')
      case 'pick up':
      case 'store':
        return updateDataByType('PAY_AT_STORE')
      case 'credit':
        return updateCreditCardMethod()
      case 'bni':
      case 'bca':
      case 'mandiri':
      case 'lainnya':
        return getBankTransferData(address, shippingType, paymentType)
      case 'installment':
        return updateDataByType('HOOLAH')
      case 'free':
      default:
        return updateDataByType('AWAIT_PAYMENT')
    }
  }

  const filterLatestAddress = (
    addressList,
    latestOrderAddress,
    shippingType,
  ) => {
    // no address available
    if (!addressList?.length) {
      return null
    }

    const addressIdField =
      shippingType === 'normal' ? 'id_address' : 'id_pickup_location'
    const curAddressID = latestOrderAddress[addressIdField]
    const [latestSelectedAddress] = addressList.filter(
      (address) => address[addressIdField] === curAddressID, // might have to fix this since user address is id_address
    )

    // no matched address
    if (!latestSelectedAddress) {
      return null
    }

    latestSelectedAddress.method = shippingType.toUpperCase()
    return latestSelectedAddress
  }

  // This will return false when these data are unavailable.
  // Shipping address
  // Shipping method
  // CC
  // Bank (or sub-bank)
  const updateCheckoutData = async (latestSelectedAddress, shippingType) => {
    const isShippingDataSet = await updateShippingData(
      latestSelectedAddress,
      shippingType,
    )
    const isPaymentDataSet = await updatePaymentData(
      latestSelectedAddress,
      shippingType,
    )
    return isShippingDataSet && isPaymentDataSet
  }

  // TYPE OF SHIPPING:
  // pickup = ttb
  // normal = delivery
  const getShippingAddress = async (shippingType) => {
    let shouldDefaulting
    if (shippingType === 'normal') {
      shouldDefaulting = AddressApiHandler.getAddress().then(
        async (userAddressesRes) => {
          const latestSelectedAddress = filterLatestAddress(
            userAddressesRes?.addresses,
            orderDetail.address,
            shippingType,
          )

          if (!latestSelectedAddress) {
            return null
          }

          const isCheckoutDataSet = await updateCheckoutData(
            latestSelectedAddress,
            shippingType,
          )
          return isCheckoutDataSet
        },
      )
    }

    if (shippingType === 'pickup') {
      shouldDefaulting = AddressApiHandler.getPickUpPoints({
        pickup_type: PomeloPinsConstant.store,
        shop_id: shop,
      }).then(async (pmloStoreRes) => {
        const latestSelectedAddress = filterLatestAddress(
          pmloStoreRes?.pickup,
          orderDetail.address,
          shippingType,
        )

        if (!latestSelectedAddress) {
          return null
        }

        const userPhoneNum = orderDetail.address.phone_mobile
        const countryCode = userPhoneNum.slice(0, 2)
        const enteredPhoneNumber = userPhoneNum.slice(2)
        latestSelectedAddress.countryCode = countryCode
        latestSelectedAddress.enteredPhoneNumber = enteredPhoneNumber

        // store the user's default TTB phone no. making it available when the user change the TTB location
        StateStorage.setCheckoutPhoneNo(enteredPhoneNumber)

        const isCheckoutDataSet = await updateCheckoutData(
          latestSelectedAddress,
          shippingType,
        )
        return isCheckoutDataSet
      })
    }

    return shouldDefaulting
  }

  const redirectToTheLink = (link) => {
    Router.push(link.href, link.as).then(() => {
      Tracking.trackEvent(Tracking.EVENT_NAME_START_CHECKOUT)
    })
  }

  const shouldRedirectWithPrefill = (link, shouldPrefill) => {
    if (!shouldPrefill) {
      redirectToTheLink(link)
    } else {
      setTimeout(() => {
        Router.push(
          {
            pathname: link.href,
            query: {
              activeStep: CHECKOUT_STEPS.CONFIRM,
              activeSubStep: CHECKOUT_SUB_STEPS.SUMMARY.id,
            },
          },
          link.as,
        ).then(() => {
          Tracking.trackEvent(Tracking.EVENT_NAME_START_CHECKOUT)
        })
      }, 100)
    }
  }

  const redirectToCheckoutPage = async (link) => {
    const isOrderAllowed = allowedOrderTypeId.includes(
      orderDetail?.id_order_type,
    )
    // cut off the default checkout flow when it is payment failed order
    const isPaymentFailedOrder = orderDetail?.current_status_code === 8

    if (!isOrderAllowed || isPaymentFailedOrder) {
      redirectToTheLink(link)
    } else if (isOrderAllowed) {
      const shippingType = orderDetail?.shipping_type
      const shouldPrefillData = await getShippingAddress(shippingType)
      shouldRedirectWithPrefill(link, shouldPrefillData)
    }
  }

  const goToCheckout = () => {
    isCheckoutClick.current = true
    const hasLatestOrder = orderList?.orders?.length >= 1 // falsy when has no order, truthy when has order
    const shouldCheckoutNormally =
      !defaultCheckoutInfoFlagValue || !hasLatestOrder

    const checkoutLink = {
      as: `/${country}/${language}/checkout`,
      href: '/checkout',
    }

    if (StateStorage.isGuestMode()) {
      const prompt_location = 'bag'

      setSuccessfulLoginRedirectUrl(checkoutLink.as)
      segmentSignInViewed(prompt_location)
      sessionStorage.setItem(
        SEGMENT_STORAGE_KEY_PROMPT_LOCATION,
        prompt_location,
      )
      updateShowLogin(true)
      StateStorage.saveLocalState('successfullogincart', checkoutLink.as)
      Tracking.trackEvent(Tracking.EVENT_NAME_START_CHECKOUT)
    } else if (shouldCheckoutNormally) {
      redirectToTheLink(checkoutLink)
    } else {
      redirectToCheckoutPage(checkoutLink)
    }
  }

  const closeCart = (e) => {
    e?.preventDefault()
    trackCartViewed(cartProducts, country, shop, EVENT.leftCart)

    updateCart({
      cartOpen: false,
      couponUsed: cartData.couponUsed,
      freeGiftOpen: false,
      isStoreCreditUsed: cartData?.isStoreCreditUsed,
    })
  }

  const openFreeGift = () => {
    showFreeGift()
    updateCart({
      cartOpen: true,
      freeGiftOpen: true,
      couponUsed: cartData.couponUsed,
      isStoreCreditUsed: cartData.isStoreCreditUsed,
    })
  }

  const tryGetCart = () => {
    setIsDuringTimeout(true)

    setTimeout(() => {
      if (!isUnmounted.current) {
        setIsDuringTimeout(false)
      }
    }, DEBOUNCE_TIME)

    getCart({
      type: 'GET',
      body: {
        is_store_credits_applied: applyStoreCredit,
      },
    })
  }

  const isPassHoolahThreshold = () => {
    const totalPrice = cartProducts?.summary?.total

    switch (shop) {
      case 1:
        // >= 130 THB
        return totalPrice >= 130
      case 2:
        // >= 5 SGD
        return totalPrice >= 5
      case 11:
        // >= 20 MYR
        return totalPrice >= 20
      default:
        return false
    }
  }

  let bodyJsx = null

  if (!isMounted) {
    return null
  }

  if (cartLoading || (cartError && isDuringTimeout) || !cartProducts) {
    bodyJsx = (
      <div className="cart-loader">
        <Loader />
      </div>
    )
  }

  if (
    cartError?.error?.code &&
    ![ADD_TO_BAG_API_CODE.overLimit, ADD_TO_BAG_API_CODE.soldOut].includes(
      cartError.error.code,
    )
  ) {
    bodyJsx = <CartGenericError tryToGetCart={() => tryGetCart()} t={t} />
  }

  if (!bodyJsx && cartProducts) {
    const authStatus = getAuthStatus(auth)
    const shopInfo = getShopInfo({ key: 'id_shop', value: shop })
    const isHoolahAvailable = !!paymentOptions?.find(
      (option) => option.type === 'hoolah',
    )
    const showHoolahSuggestion =
      hoolahFlagValue && isHoolahAvailable && isPassHoolahThreshold()

    bodyJsx = (
      <CartMainBody
        apiError={apiError}
        cartData={cartData}
        cartJustOpened={!isPreviousCartOpened.current && cartData.cartOpen}
        cartProducts={cartProducts}
        cartQuantity={cartQuantity?.total_items}
        country={country}
        goToCheckout={() => goToCheckout()}
        openFreeGift={() => openFreeGift()}
        setApiError={setApiError}
        showFallbackModal={showFallbackModal}
        showHoolahSuggestion={showHoolahSuggestion}
        showLoyaltyBenefit={
          shopInfo?.is_allow_loyalty &&
          authStatus.isFetched &&
          authStatus.isLoyaltyMember
        }
      />
    )
  }

  return (
    <div className="shopping-bag">
      <style jsx>{styles}</style>
      <div className="top-header">
        <Icon
          alt="close-icon"
          src={ICONS.close}
          className="svg-close"
          size={ICON_SIZE.medium}
          onClick={(e) => closeCart(e)}
          cy="close_cart"
        />
        <span className={clsx(Typo.subtitle1, 'shopping-bag__title')}>
          {t('My Shopping Bag')}
        </span>
        <CartWishlistIcon
          closeCart={(e) => closeCart(e)}
          updateShowLogin={updateShowLogin}
        />
      </div>
      {bodyJsx}
    </div>
  )
}

Cart.defaultProps = {
  applyStoreCredit: undefined,
  cartError: null,
  cartProducts: null,
  cartQuantity: undefined,
  orderDetail: undefined,
  orderList: undefined,
  pomeloPins: undefined,
}

Cart.propTypes = {
  applyStoreCredit: PropTypes.bool,
  auth: PropTypes.shape({
    customerData: PropTypes.shape({ isFetched: PropTypes.bool }),
    isGuestMode: PropTypes.bool,
    user: PropTypes.shape({
      id_customer: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  }).isRequired,
  cartData: PropTypes.shape({
    cartOpen: PropTypes.bool,
    isStoreCreditUsed: PropTypes.bool,
    couponUsed: PropTypes.bool,
  }).isRequired,
  cartError: PropTypes.shape({
    error: PropTypes.shape({
      code: PropTypes.string,
    }),
  }),
  cartLoading: PropTypes.bool.isRequired,
  cartProducts: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id_product: PropTypes.number,
      }),
    ),
    summary: PropTypes.shape({
      subtotal: PropTypes.number,
      total: PropTypes.number,
    }),
  }),
  cartQuantity: PropTypes.shape({
    total_items: PropTypes.number,
  }),
  checkSoldOutItem: PropTypes.func.isRequired,
  getCart: PropTypes.func.isRequired,
  getCartQuantity: PropTypes.func.isRequired,
  getOrderList: PropTypes.func.isRequired,
  internationalization: PropTypes.shape({
    country: PropTypes.string,
    currency: PropTypes.number,
    language: PropTypes.string,
    locale: PropTypes.number,
    shop: PropTypes.number,
  }).isRequired,
  invalidateCartBadge: PropTypes.func.isRequired,
  isPhone: PropTypes.bool.isRequired,
  orderDetail: PropTypes.shape({
    total_items: PropTypes.number,
    shipping_type: PropTypes.string,
  }),
  orderList: PropTypes.shape({
    orders: PropTypes.arrayOf(
      PropTypes.shape({
        id_order: PropTypes.number,
        is_offline: PropTypes.bool,
      }),
    ),
  }),
  pomeloPins: PropTypes.shape({}),
  resetOrderList: PropTypes.func.isRequired,
  setSuccessfulLoginRedirectUrl: PropTypes.func.isRequired,
  showFallbackModal: PropTypes.func.isRequired,
  showFreeGift: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateCart: PropTypes.func.isRequired,
  updatePayment: PropTypes.func.isRequired,
  updateShippingAddress: PropTypes.func.isRequired,
  updateShippingMethod: PropTypes.func.isRequired,
  updateShowLogin: PropTypes.func.isRequired,
}

const Extended = withI18next()(Cart)

export default connect(
  (state) => ({
    applyStoreCredit: state.cartData.isStoreCreditUsed,
    auth: state.auth,
    cartData: state.cartData,
    cartError: state.cart.error,
    cartLoading: state.cart.isFetching,
    cartProducts: state.cart.payload,
    cartQuantity: state.cartBadge.payload,
    internationalization: state.internationalization,
    isPhone: state.device.isPhone,
    orderList: state.orders.payload,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        checkSoldOutItem: cartItemsDuck.creators.checkSoldOutItem,
        getCart: cartDuck.creators.get,
        // TODO: refactor to header component to fetch cart badge every time that cart updated
        getCartQuantity: (options) =>
          cartBadgeDuck.creators.get({
            ...options,
            type: 'GET_CART_QUANTITY',
          }),
        getOrderList: ordersDuck.creators.get,
        invalidateCartBadge: cartBadgeDuck.creators.invalidate,
        resetOrderList: ordersDuck.creators.invalidate,
        setSuccessfulLoginRedirectUrl:
          authDuck.creators.setSuccessfulLoginRedirectUrl,
        showFreeGift: () => modalDuck.creators.show(CART_FREE_GIFT_MODAL_ID),
        updateCart: cartItemsDuck.creators.update,
        updatePayment: checkoutDuck.creators.updatePayment,
        updateShippingAddress: checkoutDuck.creators.updateShippingAddress,
        updateShippingMethod: checkoutDuck.creators.updateShippingMethod,
        updateShowLogin: authDuck.creators.updateShowLogin,
      },
      dispatch,
    ),
)(Extended)
