import env from 'config/env'
import { getAssetPath } from 'lib/utils/common/commonUtils'

export const ASSET_PREFIX = `${getAssetPath()}/static/img/pages/no-result`

export const MOBILE_DATA = [
  {
    src: `${env.IMG_HOST}img/error/cate_1_wm.jpg`,
    label: 'dresses',
    labelKey: 'ERROR_DEFAULT_LABEL_1',
    link: {
      as: '/clothes/dresses',
      href: '/category?id=36',
    },
  },
  {
    src: `${env.IMG_HOST}img/error/cate_2_wm.jpg`,
    label: 'tops',
    labelKey: 'ERROR_DEFAULT_LABEL_2',
    link: {
      as: '/clothes/tops',
      href: '/category?id=6',
    },
  },
  {
    src: `${env.IMG_HOST}img/error/cate_3_wm.jpg`,
    label: 'bottoms',
    labelKey: 'ERROR_DEFAULT_LABEL_3',
    link: {
      as: '/clothes/bottoms',
      href: '/category?id=7',
    },
  },
]

export const DESKTOP_DATA = [
  {
    src: `${env.IMG_HOST}img/error/cate_1_w.jpg`,
    label: 'dresses',
    labelKey: 'ERROR_DEFAULT_LABEL_1',
    link: {
      as: '/clothes/dresses',
      href: '/category?id=36',
    },
  },
  {
    src: `${env.IMG_HOST}img/error/cate_2_w.jpg`,
    label: 'tops',
    labelKey: 'ERROR_DEFAULT_LABEL_2',
    link: {
      as: '/clothes/tops',
      href: '/category?id=6',
    },
  },
  {
    src: `${env.IMG_HOST}img/error/cate_3_w.jpg`,
    label: 'bottoms',
    labelKey: 'ERROR_DEFAULT_LABEL_3',
    link: {
      as: '/clothes/bottoms',
      href: '/category?id=7',
    },
  },
  {
    src: `${env.IMG_HOST}img/error/cate_4_w.jpg`,
    label: 'modest wear',
    labelKey: 'ERROR_DEFAULT_LABEL_4',
    link: {
      as: '/clothes/modest-wear',
      href: '/category?id=901',
    },
  },
]

export const NEW_UI_DESKTOP_DATA = [
  {
    src: `${ASSET_PREFIX}/dress.png`,
    label: 'dress',
    labelKey: 'ERROR_DEFAULT_LABEL_DRESS',
    link: {
      as: '/clothes/dress',
      href: '/category?id=36',
    },
  },
  {
    src: `${ASSET_PREFIX}/bag.png`,
    label: 'bag',
    labelKey: 'ERROR_DEFAULT_LABEL_BAG',
    link: {
      as: '/bag',
      href: '/category?id=74',
    },
  },
  {
    src: `${ASSET_PREFIX}/shoe.png`,
    label: 'shoes',
    labelKey: 'ERROR_DEFAULT_LABEL_SHOES',
    link: {
      as: '/shoes',
      href: '/category?id=415',
    },
  },
  {
    src: `${ASSET_PREFIX}/swimwear.png`,
    label: 'swimwear',
    labelKey: 'ERROR_DEFAULT_LABEL_SWIMWEAR',
    link: {
      as: '/swimwear',
      href: '/category?id=83',
    },
  },
]

export const NEW_UI_MOBILE_DATA = [
  ...NEW_UI_DESKTOP_DATA,
  {
    src: `${ASSET_PREFIX}/blazer.png`,
    label: 'blazer',
    labelKey: 'ERROR_DEFAULT_LABEL_BLAZER',
    link: {
      as: '/clothes/blazer',
      href: '/category?id=126',
    },
  },
  {
    src: `${ASSET_PREFIX}/jeans.png`,
    label: 'jeans',
    labelKey: 'ERROR_DEFAULT_LABEL_JEANS',
    link: {
      as: '/jeans',
      href: '/category?id=971',
    },
  },
]

export const PDP_ERROR_MESSAGE = {
  title: { key: 'PDP_ERROR_MESSAGE_TITLE', message: 'Product Unavailable' },
  description: {
    key: 'PDP_ERROR_MESSAGE_DESCRIPTION',
    message:
      'The product you requested is not available in the selected country',
  },
  placeholder: {
    key: 'PDP_ERROR_MESSAGE_PLACEHOLDER',
    message: 'Search a city or postal code…',
  },
}
