import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/remote-config'

import env from 'config/env'

const config = {
  apiKey: env.FIREBASE_API_KEY,
  authDomain: env.FIREBASE_AUTH_DOMAIN,
  projectId: env.FIREBASE_PROJECT_ID,
  appId: env.FIREBASE_APP_ID,
}

// To avoid the error: set mock firebase remoteConfig before initiate firebase
// eslint-disable-next-line import/no-mutable-exports
let remoteConfig = {
  getValue: () => ({}),
  fetchAndActivate: () => ({}),
}

if (!firebase.apps.length) {
  const app = firebase.initializeApp(config)
  if (typeof window !== 'undefined') {
    remoteConfig = firebase.remoteConfig(app)
  }
}

export { remoteConfig }
export default firebase
