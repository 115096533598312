const BADGE_HORIZONTAL = {
  left: 'left',
  right: 'right',
}

const BADGE_VERTICAL = {
  top: 'top',
  bottom: 'bottom',
}

const BADGE_COLOR = {
  primary: 'pri',
  secondary: 'sec',
}

export { BADGE_HORIZONTAL, BADGE_VERTICAL, BADGE_COLOR }
